const tiposBase = {
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_descripcion', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        }
    ]
}
export const tiposRelacionesPersonas = {
    title: 'Tipos relaciones',
    service: 'tipos-relaciones-personas',
    id: 'tipos-relaciones-personas',
    ...tiposBase
}
export const estatusCitas = {
    title: 'Estatus eventos',
    service: 'status-schedules',
    id: 'status-schedules',
    ...tiposBase
}

export const tiposCitas = {
    title: 'tipos eventos',
    service: 'types-schedules',
    id: 'types-schedules',
    ...tiposBase
}

export const diasSemana = {
    title: 'Dias de la semana',
    service: 'week-days',
    id: 'week-days',
    ...tiposBase
}

export const tiposAlertas = {
    title: 'Tipos de alertas',
    service: 'types-alerts',
    id: 'types-alerts',
    ...tiposBase
}

export const tiposDispositivos = {
    title: 'Tipos dispositivos',
    service: 'types-devices',
    id: 'types-devices',
    ...tiposBase
}

export const tiposActivosIndustriales = {
    title: 'Tipos Actividades',
    service: 'types-industries-assets',
    id: 'types-industries-assets',
    ...tiposBase
}

export const unidadesTiempo = {
    title: 'Unidades de tiempo',
    service: 'time-units',
    id: 'time-units',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_abreviatura', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_abreviatura',
            numeric: false,
            disablePadding: true,
            label: 'Abreviatura',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        }
    ]
}
export const medidas = {
    title: 'Medidas',
    service: 'measures',
    id: 'measures',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_abreviatura', type: 'text', default: true },
        { id: 'vc_descripcion', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_abreviatura',
            numeric: false,
            disablePadding: true,
            label: 'Abreviatura',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        }
    ]
}
export const tiposAreas = {
    title: 'Tipos Areas',
    service: 'types-sections',
    id: 'types-sections',
    ...tiposBase
}

export const roles = {
    title: 'Roles',
    service: 'roles',
    id: 'roles',
    ...tiposBase
}

export const eventos = {
    title: 'Eventos',
    service: 'events',
    id: 'events',
    searchFields: [
        { id: 'id', type: 'text', default: true },
        { id: 'vc_raw', type: 'text', default: true },
        { id: 'vc_aplicacionExt', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_raw',
            numeric: false,
            disablePadding: true,
            label: 'Hexadecimal',
        },
        {
            id: 'id_aplicacionExt',
            numeric: false,
            disablePadding: true,
            label: 'Aplicación Externa',
        },
        {
            id: 'dt_received',
            numeric: false,
            component: 'date',
            disablePadding: true,
            readOnly: true,
            label: 'Fecha de evento'
        },
        {
            id: 'id_aplicacion',
            numeric: false,
            disablePadding: true,
            label: 'Aplicación',
            // value: { type: 'user', keyPath: 'id' },
            readOnly: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'applications',
                vc_nameReducer: 'applications',
                id_model: 'applications',
                key: 'id_aplicacion',
                keyTable: 'id',
                fieldsDisplay: [
                    'id',
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                    ' '
                ]
            }
        }
    ]
}
export const eventosRegistos = {
    title: 'Registros',
    service: 'events-registers',
    id: 'events-registers',
    searchFields: [
        { id: 'id_event', type: 'text', default: true },
        { id: 'nu_value', type: 'text', default: true },
        { id: 'vc_value', type: 'text', default: true },
        { id: 'vc_field', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_event',
            numeric: false,
            disablePadding: true,
            label: 'Evento',
            readOnly: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'events',
                vc_nameReducer: 'events',
                id_model: 'events',
                key: 'id_event',
                keyTable: 'id',
                fieldsDisplay: [
                    'id',
                    'id_aplicacionExt'
                ],
                fieldsDisplaySeparators: [
                    ' - '
                ]
            }
        },
        {
            id: 'vc_field',
            numeric: false,
            disablePadding: true,
            label: 'Campo',
        },
        {
            id: 'vc_value',
            numeric: false,
            disablePadding: true,
            label: 'Valor texto',
        },
        {
            id: 'nu_value',
            numeric: true,
            disablePadding: true,
            label: 'Valor numerico',
        }
    ]
}

export const sexos = {
    title: 'Sexos',
    service: 'sexos',
    id: 'sexos',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        }
    ]
}

export const gruposTrabajo = {
    title: 'Grupos de trabajo',
    service: 'work-groups',
    id: 'work-groups',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            required: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        },
        {
            id: 'usuarios',
            numeric: false,
            disablePadding: true,
            label: 'Usuarios',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'users-work-groups',
                vc_nameReducer: 'users-work-groups',
                id_model: 'users-work-groups',
                key: 'usuarios',
                keyTable: 'id_usuario',
                keyItem: 'id',
                foreignKey: 'id_grupo',
                objectPath: 'usuario',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'activos',
            numeric: false,
            disablePadding: true,
            label: 'Actividades',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'work-groups-sections',
                vc_nameReducer: 'work-groups-sections',
                id_model: 'work-groups-sections',
                key: 'activos',
                keyTable: 'id_activo',
                keyItem: 'id',
                foreignKey: 'id_grupo',
                objectPath: 'activo',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [

                ]
            }
        }
    ]
}

export const entidades = {
    title: 'Entidades',
    service: 'entidades',
    id: 'entidades',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_cve', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_cve',
            numeric: false,
            disablePadding: true,
            label: 'Clave',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        }
    ]
}
export const tiposClases = {
    title: 'Tipos Clases',
    service: 'types-classes',
    id: 'types-classes',
    ...tiposBase
}
export const clases = {
    title: 'Clases',
    service: 'classes',
    id: 'classes',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_descripcion', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'id_clase',
            numeric: false,
            disablePadding: true,
            label: 'Clase Padre',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'classes',
                vc_nameReducer: 'classes',
                key: 'id_clase',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_tipo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-classes',
                vc_nameReducer: 'types-classes',
                key: 'id_tipo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        }
    ]
}



export const unidadesMedida = {
    title: 'Unidades Medida',
    service: 'unidades-medida',
    id: 'unidades-medida',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_descripcion', type: 'text' },
        { id: 'vc_nota', type: 'text' },
        { id: 'vc_cve', type: 'text' },
        { id: 'vc_simbolo', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_cve',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        },
        {
            id: 'vc_nota',
            numeric: false,
            disablePadding: true,
            label: 'Nota',
        },
        {
            id: 'vc_simbolo',
            numeric: false,
            disablePadding: true,
            label: 'Simbolo',
        }
    ]
}
export const secciones = {
    title: 'Areas',
    service: 'sections',
    id: 'sections',
    // and: [{ id_tipo: '2047da34-2f0f-460f-9924-9fe12e3f8b93' }],
    // defaultValues: {
    //     id_tipo: '2047da34-2f0f-460f-9924-9fe12e3f8b93'
    // },
    defaultSystem: [
        { vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' },
        // { vc_field: 'id_tipo', type: 'static', value: '2047da34-2f0f-460f-9924-9fe12e3f8b93' }
    ],
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_descripcion', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_dependencia',
            numeric: false,
            disablePadding: true,
            label: 'Locación',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'dependences',
                vc_nameReducer: 'dependences',
                id_model: 'dependences',
                key: 'id_dependencia',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_seccion',
            numeric: false,
            disablePadding: true,
            label: 'Área',
            component: 'select',
            hidden: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'sections',
                key: 'id_seccion',
                keyTable: 'id',
                defaultSystem: [
                    { vc_field: 'id_dependencia', type: 'field', key: 'id_dependencia', required: true },
                ],
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_tipo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-sections',
                vc_nameReducer: 'types-sections',
                id_model: 'types-sections',
                key: 'id_tipo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        // {
        //     id: 'id_tipoActivo',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Tipo Activo',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'types-industries-assets',
        //         vc_nameReducer: 'types-industries-assets',
        //id_model: 'types-industries-assets',
        //         key: 'id_tipoActivo',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        },
        {
            id: 'activos',
            numeric: false,
            disablePadding: true,
            label: 'Activos',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'industries-assets',
                key: 'activos',
                keyTable: 'id',
                keyItem: 'id',
                foreignKey: 'id_seccion',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}

export const activosIndustriales = {
    title: 'Actividades',
    service: 'sections',
    id: 'industries-assets',
    and: [{ id_tipo: '7ec98d6a-55ad-42be-b50d-b0482342ef5e' }],
    defaultValues: {
        id_tipo: '7ec98d6a-55ad-42be-b50d-b0482342ef5e'
    },
    defaultSystem: [
        { vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' },
    ],
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_descripcion', type: 'text' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        // {
        //     id: 'id_dependencia',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Locación',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'dependences',
        //         vc_nameReducer: 'dependences',
        //id_model: 'dependences',
        //         key: 'id_dependencia',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        // {
        //     id: 'id_seccion',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Área',
        //     required: true,
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'sections',
        //         vc_nameReducer: 'sections',
        //         id_model: 'sections',
        //         key: 'id_seccion',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        // {
        //     id: 'id_tipo',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Tipo',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'types-sections',
        //         vc_nameReducer: 'types-sections',
        // id_model: 'types-sections',
        //         key: 'id_tipo',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'id_tipoActivo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo Actividad',
            required: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-industries-assets',
                vc_nameReducer: 'types-industries-assets',
                id_model: 'types-industries-assets',
                key: 'id_tipoActivo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            required: true,
            label: 'Nombre',
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
        },
        {
            id: 'configuraciones',
            numeric: false,
            disablePadding: true,
            label: 'Configuraciones',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'schedules-configuration',
                vc_nameReducer: 'schedules-configuration',
                id_model: 'schedules-configuration',
                key: 'configuraciones',
                keyTable: 'id',
                keyItem: 'id',
                foreignKey: 'id_activo',
                fieldsDisplay: [
                    'vc_descripcion',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        // {
        //     id: 'dispositivos',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Dispositivos',
        //     component: 'select',
        //     mode: 'fixed',
        //     relation: {
        //         id_type: 'toMany',
        //         vc_nameTable: 'devices-sections',
        //         vc_nameReducer: 'devices-sections',
        //         id_model: 'devices-sections',
        //         key: 'dispositivos',
        //         keyTable: 'id',
        //         keyItem: 'id',
        //         foreignKey: 'id_seccion',
        //         fieldsDisplay: [
        //             'vc_nombre',
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'grupos',
            numeric: false,
            disablePadding: true,
            label: 'Grupos',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'work-groups-sections',
                vc_nameReducer: 'work-groups-sections',
                id_model: 'work-groups-sections',
                key: 'grupos',
                keyTable: 'id',
                keyItem: 'id',
                foreignKey: 'id_activo',
                objectPath: 'grupo',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}

export const personasRelaciones = {
    title: 'Relaciones',
    service: 'personas-relaciones',
    id: 'personas-relaciones',
    searchFields: [
        { id: 'vc_nombreCompleto', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_personaDetalle',
            numeric: false,
            disablePadding: true,
            label: 'Persona',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'personas-detalle',
                vc_nameReducer: 'personas-detalle',
                id_model: 'personas-detalle',
                key: 'id_persona',
                keyTable: 'id_persona',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                    'vc_curp',
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' ',
                    ' - ',
                ]
            }
        },
        {
            id: 'id_tipoRelacion',
            numeric: false,
            disablePadding: true,
            label: 'Tipo relación',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'tipos-relaciones-personas',
                vc_nameReducer: 'tipos-relaciones-personas',
                id_model: 'tipos-relaciones-personas',
                key: 'id_tipoRelacion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}

export const usuarios = {
    title: 'Usuarios',
    service: 'usuarios',

    id: 'usuarios',
    searchFields: [
        { id: 'id', type: 'text', default: true },
        { id: 'vc_curp', type: 'text', default: true },
        { id: 'email', type: 'text', default: true },
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_paterno', type: 'text' },
        { id: 'vc_materno', type: 'text' }
    ],
    wrapperComponents: 'scanner-gun-curp',
    headComponents: 'scanner-webcam',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_curp',
            numeric: false,
            disablePadding: true,
            label: 'CURP',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            required: true
        },
        {
            id: 'vc_paterno',
            numeric: false,
            disablePadding: true,
            label: 'Paterno',
            required: true
        },
        {
            id: 'vc_materno',
            numeric: false,
            disablePadding: true,
            label: 'Materno',
        },
        // {
        //     id: 'id_sexo',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Sexo',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'genders',
        //         vc_nameReducer: 'genders',
        //         id_model: 'genders',
        //         key: 'id_sexo',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'dt_nacimiento',
            numeric: false,
            disablePadding: true,
            label: 'Fecha nacimiento',
            required: true
        },
        {
            id: 'vc_telefono',
            numeric: false,
            disablePadding: true,
            label: 'Telefono',
            required: false
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            component: 'email',
            label: 'E-Mail',
            required: true
        },
        {
            id: 'password',
            numeric: false,
            disablePadding: true,
            component: 'password',
            label: 'Contraseña',
            required: true
        }
    ]
}
export const usuariosInstituciones = {
    title: 'Instituciones de usuario',
    service: 'users-institutions',
    id: 'users-institutions',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    searchFields: [

    ],
    focus: [
        {
            variant: 'caption',
            path: 'usuario',
            fieldsDisplay: [
                'vc_nombre',
                'vc_paterno',
                'vc_materno'
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        },
        {
            variant: 'caption',
            path: 'institucion',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                    'email'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' ',
                    ' - (',
                    ')'
                ]
            }
        },
        {
            id: 'roles',
            numeric: false,
            disablePadding: true,
            label: 'Roles',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'users-roles',
                vc_nameReducer: 'users-roles',
                id_model: 'users-roles',
                key: 'roles',
                keyTable: 'id',
                keyItem: 'id_usuario',
                foreignKey: 'id_usuario',
                objectPath: 'rol',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'grupos',
            numeric: false,
            disablePadding: true,
            label: 'Grupos de trabajo',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'users-work-groups',
                vc_nameReducer: 'users-work-groups',
                id_model: 'users-work-groups',
                key: 'grupos',
                keyTable: 'id',
                keyItem: 'id_usuario',
                foreignKey: 'id_usuario',
                objectPath: 'grupo',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'calendarios',
            numeric: false,
            disablePadding: true,
            label: 'Calendarios',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'users-calendars',
                vc_nameReducer: 'users-calendars',
                id_model: 'users-calendars',
                key: 'calendarios',
                keyTable: 'id',
                keyItem: 'id_usuario',
                foreignKey: 'id_usuario',
                objectPath: 'calendario',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}

export const citasUsuarios = {
    title: 'Invitados',
    service: 'schedules-users',
    id: 'schedules-users',
    searchFields: [

    ],
    focus: [
        {
            variant: 'caption',
            path: 'usuario',
            fieldsDisplay: [
                'vc_nombre',
                'vc_paterno',
                'vc_materno'
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        },
        {
            variant: 'caption',
            path: 'cita',
            fieldsDisplay: [
                'dt_inicio',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                    'email'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' ',
                    ' - (',
                    ')'
                ]
            }
        },
        {
            id: 'id_cita',
            numeric: false,
            disablePadding: true,
            label: 'Evento',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'schedules',
                vc_nameReducer: 'schedules',
                id_model: 'schedules',
                key: 'id_cita',
                keyTable: 'id',
                fieldsDisplay: [
                    'dt_inicio',
                ],
                fieldsDisplaySeparators: [

                ]
            }
        }
    ]
}
export const meetUsuarios = {
    title: 'Invitados',
    service: 'meets-users',
    id: 'meets-users',
    searchFields: [

    ],
    focus: [
        {
            variant: 'caption',
            path: 'usuario',
            fieldsDisplay: [
                'vc_nombre',
                'vc_paterno',
                'vc_materno'
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        },
        {
            variant: 'caption',
            path: 'meet',
            fieldsDisplay: [
                'id',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                    'email'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' ',
                    ' - (',
                    ')'
                ]
            }
        },
        {
            id: 'id_meet',
            numeric: false,
            disablePadding: true,
            label: 'Evento',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'meets',
                vc_nameReducer: 'meets',
                id_model: 'meets',
                key: 'id_meet',
                keyTable: 'id',
                fieldsDisplay: [
                    'id',
                ],
                fieldsDisplaySeparators: [

                ]
            }
        }
    ]
}

export const meets = {
    title: 'Videoconferencias',
    service: 'meets',
    id: 'meets',
    searchFields: [

    ],
    defaultSystem: [
        { vc_field: 'id_usuario', type: 'session', param: 'user', key: 'id' }
    ],
    focus: [
        {
            variant: 'caption',
            path: 'usuario',
            fieldsDisplay: [
                'vc_nombre',
                'vc_paterno',
                'vc_materno'
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_pass',
            numeric: false,
            disablePadding: true,
            label: 'Password',
            icon: 'proteccion.svg',
            required: false
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            required: false
        },
        {
            id: 'invitados',
            numeric: false,
            disablePadding: true,
            label: 'Invitados',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'meets-users',
                vc_nameReducer: 'meets-users',
                id_model: 'meets-users',
                key: 'invitados',
                keyTable: 'id',
                keyItem: 'id',
                foreignKey: 'id_meet',
                objectPath: 'usuario',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' ',
                ]
            }
        }
    ]
}

export const usuariosCalendarios = {
    title: 'Calendarios de usuario',
    service: 'users-calendars',
    id: 'users-calendars',
    defaultSystem: [
        { vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' },
    ],
    searchFields: [

    ],
    focus: [
        {
            variant: 'caption',
            path: 'calendario',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                    'email'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' ',
                    ' - (',
                    ')'
                ]
            }
        },
        {
            id: 'id_calendario',
            numeric: false,
            disablePadding: true,
            label: 'Calendario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'calendars',
                vc_nameReducer: 'calendars',
                id_model: 'calendars',
                key: 'id_calendario',
                keyTable: 'id',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                    ' '
                ]
            }
        }
    ]
}
const usuariosCalendariosFilter = {
    ...usuariosCalendarios,
    id: 'users-calendars-filter',
    defaultSystem: [
        { vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' },
        { vc_field: 'id_usuario', type: 'session', param: 'user', key: 'id' }
    ],
}
export const usuariosInstitucionesPermisos = {
    title: 'Instituciones de usuario (Master)',
    service: 'users-institutions',
    id: 'users-institutions',
    searchFields: [

    ],
    focus: [
        {
            variant: 'caption',
            path: 'usuario',
            fieldsDisplay: [
                'vc_nombre',
                'vc_paterno',
                'vc_materno'
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        },
        {
            variant: 'caption',
            path: 'institucion',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_institucion',
            numeric: false,
            disablePadding: true,
            label: 'Empresa',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'institutions',
                vc_nameReducer: 'institutions',
                id_model: 'institutions-masters',
                key: 'id_institucion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [

                ]
            }
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        }
    ]
}


export const usuariosGrupos = {
    title: 'Grupos de trabajo del usuario',
    service: 'users-work-groups',
    id: 'users-work-groups',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    focus: [
        {
            variant: 'caption',
            fontWeight: 700,
            path: 'usuario',
            fieldsDisplay: [
                'vc_nombre',
                'vc_paterno',
                'vc_materno'
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        },
        {
            variant: 'caption',
            path: 'grupo',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        },
        {
            variant: 'caption',
            path: 'institucion',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    searchFields: [

    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_grupo',
            numeric: false,
            disablePadding: true,
            label: 'Grupo de trabajo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'work-groups',
                vc_nameReducer: 'work-groups',
                id_model: 'work-groups',
                key: 'id_grupo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'users-institutions',
                vc_nameReducer: 'users-institutions',
                key: 'id_usuario',
                keyTable: 'id_usuario',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                objectPath: 'usuario',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        }
    ]
}

export const gruposActivos = {
    title: 'Grupos actividades',
    service: 'work-groups-sections',
    id: 'work-groups-sections',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    focus: [
        {
            variant: 'caption',
            fontWeight: 700,
            path: 'activo',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [
                ' ',
                ' '
            ]
        },
        {
            variant: 'caption',
            path: 'grupo',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        },
        {
            variant: 'caption',
            path: 'institucion',
            fieldsDisplay: [
                'vc_nombre',
            ],
            fieldsDisplaySeparators: [

            ]
        }
    ],
    searchFields: [

    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_grupo',
            numeric: false,
            disablePadding: true,
            label: 'Grupo de trabajo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'work-groups',
                vc_nameReducer: 'work-groups',
                id_model: 'work-groups',
                key: 'id_grupo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'id_activo',
            numeric: false,
            disablePadding: true,
            label: 'Actividad',
            component: 'select',
            required: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'industries-assets',
                key: 'id_activo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}

export const usuariosRoles = {
    title: 'Roles de usuario',
    service: 'users-roles',
    id: 'users-roles',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    searchFields: [

    ],
    columns: [
        // {
        //     id: 'id',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Id',
        // },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'id_rol',
            numeric: false,
            disablePadding: true,
            label: 'Rol',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'roles',
                vc_nameReducer: 'roles',
                id_model: 'roles',
                key: 'id_rol',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}

export const usuariosRolesMaster = {
    title: 'Roles de usuario (Master)',
    service: 'users-roles',
    id: 'users-roles',
    searchFields: [

    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_institucion',
            numeric: false,
            disablePadding: true,
            label: 'Empresa',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'institutions',
                vc_nameReducer: 'institutions',
                id_model: 'institutions-masters',
                key: 'id_institucion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [

                ]
            }
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id_usuario',
                objectPath: 'usuario',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'id_rol',
            numeric: false,
            disablePadding: true,
            label: 'Rol',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'roles',
                vc_nameReducer: 'roles',
                id_model: 'roles',
                key: 'id_rol',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}
export const usuariosPermisosSecciones = {
    title: 'Permisos áreas',
    service: 'users-sections',
    id: 'users-sections',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    searchFields: [

    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            required: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'usuarios',
                vc_nameReducer: 'usuarios',
                id_model: 'usuarios',
                key: 'id_usuario',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'id_seccion',
            numeric: false,
            disablePadding: true,
            label: 'Área',
            required: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'sections',
                key: 'id_seccion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}
export const personas = {
    title: 'Personas',
    service: 'personas-detalle',
    id: 'personas-detalle',
    searchFields: [
        { id: 'vc_curp', type: 'text', default: true },
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_paterno', type: 'text' },
        { id: 'vc_materno', type: 'text' }
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_persona',
            numeric: false,
            disablePadding: true,
            label: 'Id Persona',
            hidden: true
        },
        {
            id: 'vc_curp',
            numeric: false,
            disablePadding: true,
            label: 'CURP',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            required: true
        },
        {
            id: 'vc_paterno',
            numeric: false,
            disablePadding: true,
            label: 'Paterno',
            required: true
        },
        {
            id: 'vc_materno',
            numeric: false,
            disablePadding: true,
            label: 'Materno',
        },
        // {
        //     id: 'id_sexo',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Sexo',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'genders',
        //         vc_nameReducer: 'genders',
        //         id_model: 'genders',
        //         key: 'id_sexo',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'dt_nacimiento',
            numeric: false,
            disablePadding: true,
            label: 'Fecha nacimiento',
        },
        {
            id: 'id_entidad',
            numeric: false,
            disablePadding: true,
            label: 'Entidad nacimiento',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'entidades',
                vc_nameReducer: 'entidades',
                id_model: 'entidades',
                key: 'id_entidad',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_cve',
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                    ' - '
                ]
            }
        }

    ]
}


export const tiposUnidadesMedidas = {
    title: 'Tipos Unidades',
    service: 'tipos-unidades-medidas',
    id: 'tipos-unidades-medidas',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            search: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        }
    ]
}



export const dispositivos = {
    title: 'Dispositivos',
    service: 'devices',
    id: 'devices',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        // {
        //     id: 'id_seccion',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Área',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'sections',
        //         vc_nameReducer: 'sections',
        //         id_model: 'sections',
        //         key: 'id_seccion',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'id_tipo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo Dispositivo',
            component: 'select',
            required: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-devices',
                vc_nameReducer: 'types-devices',
                id_model: 'types-devices',
                key: 'id_tipo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_eui',
            numeric: false,
            disablePadding: true,
            label: 'EUI',
            required: true,
        },
        {
            id: 'vc_addr',
            numeric: false,
            disablePadding: true,
            label: 'MAC Adress',
            required: true,
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            required: true,
            search: true
        },
        {
            id: 'nu_ajusteCero',
            numeric: true,
            disablePadding: true,
            label: 'Ajuste Cero',
            conditions: [
                { vc_condition: '=', value: '05df5a70-025e-40d1-8198-a7cda1d36f00', type: 'field', key: 'id_tipo' },
            ]
        },
        {
            id: 'nu_multiplicador',
            numeric: true,
            disablePadding: true,
            label: 'Multiplicador',
            conditions: [
                { vc_condition: '=', value: '05df5a70-025e-40d1-8198-a7cda1d36f00', type: 'field', key: 'id_tipo' },
            ]
        }
    ]
}

export const dispositivosSecciones = {
    title: 'Vinculación de dispositivos',
    service: 'devices-sections',
    id: 'devices-sections',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
    ],
    defaultSystem: [
        { vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_seccion',
            numeric: false,
            disablePadding: true,
            label: 'Activo industrial',
            component: 'select',
            required: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'industries-assets',
                key: 'id_seccion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_dispositivo',
            numeric: false,
            disablePadding: true,
            label: 'Dispositivo',
            component: 'select',
            width: 'fullWidth',
            required: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'devices',
                vc_nameReducer: 'devices',
                id_model: 'devices',
                key: 'id_dispositivo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            search: true
        },
        {
            id: 'vc_lat',
            numeric: false,
            disablePadding: true,
            label: 'Latitud',
            search: true
        },
        {
            id: 'vc_lon',
            numeric: false,
            disablePadding: true,
            label: 'Longitud',
            search: true
        },
        {
            id: 'alarmas',
            numeric: false,
            disablePadding: true,
            label: 'Alarmas',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'devices-alarms',
                vc_nameReducer: 'devices-alarms',
                id_model: 'devices-alarms',
                key: 'alarmas',
                keyTable: 'id',
                keyItem: 'id',
                foreignKey: 'id_dispositivoSeccion',
                fieldsDisplay: [
                    'nu_min',
                    'nu_max',
                ],
                fieldsDisplaySeparators: [
                    ' - '
                ]
            }
        }
    ]
}

export const dispositivosMedidasRangos = {
    title: 'Rango de alerta',
    service: 'alerts',
    id: 'alerts',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_tipoDispositivoMedida',
            numeric: false,
            disablePadding: true,
            label: 'Medida',
            component: 'select',
            required: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'types-devices-measures',
                key: 'id_tipoDispositivoMedida',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_dispositivo',
            numeric: false,
            disablePadding: true,
            label: 'Dispositivo',
            component: 'select',
            width: 'fullWidth',
            required: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'devices',
                vc_nameReducer: 'devices',
                id_model: 'devices',
                key: 'id_dispositivo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            search: true
        },
        {
            id: 'vc_lat',
            numeric: false,
            disablePadding: true,
            label: 'Latitud',
            search: true
        },
        {
            id: 'vc_lon',
            numeric: false,
            disablePadding: true,
            label: 'Longitud',
            search: true
        }
    ]
}

export const dispositivosMedidas = {
    title: 'Dispositivos Medidas',
    service: 'devices-measures',
    id: 'devices-measures',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_dispositivo',
            numeric: false,
            disablePadding: true,
            label: 'Dispositivo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'devices',
                vc_nameReducer: 'devices',
                id_model: 'devices',
                key: 'id_dispositivo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_medida',
            numeric: false,
            disablePadding: true,
            label: 'Medida',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'measures',
                vc_nameReducer: 'measures',
                id_model: 'measures',
                key: 'id_medida',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'vc_fieldName',
            numeric: false,
            disablePadding: true,
            label: 'Nombre campo',
            search: true
        }
    ]
}


export const dispositivosAlarmas = {
    title: 'Dispositivos Alarmas',
    service: 'devices-alarms',
    id: 'devices-alarms',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_dispositivoSeccion',
            numeric: false,
            disablePadding: true,
            label: 'Dispositivo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'devices-sections',
                vc_nameReducer: 'devices-sections',
                id_model: 'devices',
                key: 'id_dispositivoSeccion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_tipoMedida',
            numeric: false,
            disablePadding: true,
            label: 'Medida',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-devices-measures',
                vc_nameReducer: 'types-devices-measures',
                id_model: 'types-devices-measures',
                key: 'id_tipoMedida',
                keyTable: 'id',
                defaultSystem: [
                    { vc_field: 'id_dispositivoSeccion', type: 'default', key: 'id_dispositivoSeccion', required: true },
                ],
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_tipoAlerta',
            numeric: false,
            disablePadding: true,
            label: 'Tipo de Alerta',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-alerts',
                vc_nameReducer: 'types-alerts',
                id_model: 'types-alerts',
                key: 'id_tipoAlerta',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'nu_min',
            numeric: true,
            disablePadding: true,
            label: 'Minimo',
        },
        {
            id: 'nu_max',
            numeric: true,
            disablePadding: true,
            label: 'Maximo',
        },
        {
            id: 'nu_toleranciaTiempo',
            numeric: true,
            disablePadding: true,
            label: 'Tolerancia maxima segundos',
        },
        {
            id: 'nu_toleranciaEventos',
            numeric: true,
            disablePadding: true,
            label: 'Tolerancia maxima eventos',
        }
    ]
}




export const instituciones = {
    title: 'Empresas',
    service: 'institutions',
    id: 'institutions',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_rfc', type: 'text', default: true },
    ],
    defaultSystem: [
        { vc_field: 'id', type: 'session', param: 'institution', key: 'id' },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_rfc',
            numeric: false,
            disablePadding: true,
            label: 'RFC',
            search: true,
            required: true
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre Comercial',
            required: true,
            search: true
        },
        {
            id: 'vc_abrevatura',
            numeric: false,
            disablePadding: true,
            label: 'vc_abreviatura',
        },
        {
            id: 'vc_razonSocial',
            numeric: false,
            disablePadding: true,
            label: 'Razón Social',
            search: true,
            required: true
        },
        {
            id: 'vc_regimenCapital',
            numeric: false,
            disablePadding: true,
            label: 'Regimen Capital',
            required: true

        }
    ]
}
export const dependencias = {
    title: 'Locaciones',
    service: 'dependences',
    id: 'dependences',
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
    ],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_institucion',
            numeric: false,
            disablePadding: true,
            label: 'Empresa',
            component: 'select',
            hidden: true,
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'institutions',
                vc_nameReducer: 'institutions',
                id_model: 'institutions',
                key: 'id_institucion',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            search: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Abreviatura',
        },
        {
            id: 'secciones',
            numeric: false,
            disablePadding: true,
            label: 'Áreas',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'sections',
                vc_nameReducer: 'sections',
                id_model: 'sections',
                key: 'areas',
                keyTable: 'id',
                keyItem: 'id',
                foreignKey: 'id_dependencia',
                fieldsDisplay: [
                    'vc_nombre',
                ],
                fieldsDisplaySeparators: [
                ]
            }
        }
    ]
}


export const tiposDispositivosMedidas = {
    title: 'Tipos Dispositivos Medidas',
    service: 'types-devices-measures',
    id: 'types-devices-measures',
    searchFields: [
        { id: 'vc_nombre', type: 'text', default: true },
        { id: 'vc_fieldName', type: 'text' },
    ],
    sort: 'nu_order',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Nombre',
            search: true
        },
        {
            id: 'id_tipo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo dispositivo',
            component: "select",
            relation: {
                id_type: 'toOne',
                vc_nameTable: "types-devices",
                vc_nameReducer: "types-devices",
                key: "id_tipo",
                keyTable: "id",
                fieldsDisplay: [
                    "vc_nombre"
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_medida',
            numeric: false,
            disablePadding: true,
            label: 'Medida',
            component: "select",
            relation: {
                id_type: 'toOne',
                vc_nameTable: "measures",
                vc_nameReducer: "measures",
                key: "id_medida",
                keyTable: "id",
                fieldsDisplay: [
                    "vc_nombre"
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'vc_tag',
            numeric: false,
            disablePadding: true,
            label: 'Tag',
            search: true
        },
        {
            id: 'vc_fieldName',
            numeric: false,
            disablePadding: true,
            label: 'Nombre campo',
            search: true
        },
        {
            id: 'nu_decimales',
            numeric: true,
            disablePadding: true,
            label: 'Decimales',
        },
        {
            id: 'nu_min',
            numeric: true,
            disablePadding: true,
            label: 'Minimo',
        },
        {
            id: 'nu_max',
            numeric: true,
            disablePadding: true,
            label: 'Maximo',
        },
        {
            id: 'nu_toleranciaTiempo',
            numeric: true,
            disablePadding: true,
            label: 'Tolerancia maxima segundos',
        },
        {
            id: 'nu_toleranciaEventos',
            numeric: true,
            disablePadding: true,
            label: 'Tolerancia maxima eventos',
        },
        {
            id: 'nu_order',
            numeric: true,
            disablePadding: true,
            label: 'Orden',
        },
        {
            id: 'id_clase',
            numeric: false,
            disablePadding: true,
            label: 'Clase',
            component: "select",
            relation: {
                id_type: 'toOne',
                vc_nameTable: "classes",
                vc_nameReducer: "classes",
                key: "id_clase",
                keyTable: "id",
                fieldsDisplay: [
                    "vc_nombre"
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'bl_default',
            numeric: true,
            disablePadding: true,
            label: 'Favorito',
        }
    ]
}

export const programacionConfig = {
    title: 'Configuación de eventos',
    service: 'schedules-configuration',
    id: 'schedules-configuration',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'vc_observaciones',
            numeric: false,
            disablePadding: true,
            label: 'Observaciones',
            search: true
        },
        {
            id: 'dt_inicio',
            numeric: false,
            component: 'date',
            disablePadding: true,
            label: 'Fecha de inicio'
        },
        {
            id: 'dt_fin',
            numeric: false,
            component: 'date',
            disablePadding: true,
            label: 'Fecha de fin'
        },
        // {
        //     id: 'id_dia',
        //     numeric: false,
        //     disablePadding: true,
        //     label: 'Día de la semana',
        //     component: 'select',
        //     relation: {
        //         id_type: 'toOne',
        //         vc_nameTable: 'week-days',
        //         vc_nameReducer: 'week-days',
        //         id_model: 'week-days',
        //         key: 'id_dia',
        //         keyTable: 'id',
        //         fieldsDisplay: [
        //             'vc_nombre'
        //         ],
        //         fieldsDisplaySeparators: [
        //         ]
        //     }
        // },
        {
            id: 'tm_inicio',
            numeric: false,
            component: 'time',
            disablePadding: true,
            label: 'Hora inicio'
        },
        {
            id: 'dt_ultima',
            numeric: false,
            component: 'date',
            disablePadding: true,
            // readOnly: true,
            label: 'Ultimo mantenimiento'
        },
        {
            id: 'id_unidadFrecuencia',
            numeric: false,
            disablePadding: true,
            label: 'Unidad de frecuencia',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'time-units',
                vc_nameReducer: 'time-units',
                id_model: 'time-units',
                key: 'id_unidadFrecuencia',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'nu_frecuencia',
            numeric: true,
            disablePadding: true,
            label: 'Frecuencia',
        }
    ]
}


export const accionesProgramadas = {
    title: 'Acciones programadas',
    service: 'schedules-actions',
    id: 'schedules-actions',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'vc_observaciones',
            numeric: false,
            disablePadding: true,
            label: 'Observaciones',
            search: true
        },
        {
            id: 'vc_url',
            numeric: false,
            disablePadding: true,
            component: 'media',
            label: 'Multimedia link',
        },
        {
            id: 'id_configuration',
            numeric: false,
            disablePadding: true,
            label: 'Configuración',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'schedules-configuration',
                vc_nameReducer: 'schedules-configuration',
                id_model: 'schedules-configuration',
                key: 'id_configuration',
                keyTable: 'id',
                fieldsDisplay: [
                    'id'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'tm_inicio',
            numeric: false,
            component: 'time',
            disablePadding: true,
            label: 'Hora inicio'
        },
        {
            id: 'dt_ultima',
            numeric: false,
            component: 'date',
            disablePadding: true,
            readOnly: true,
            label: 'Ultimo evento'
        },
        {
            id: 'id_unidadFrecuencia',
            numeric: false,
            disablePadding: true,
            label: 'Unidad de frecuencia',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'time-units',
                vc_nameReducer: 'time-units',
                id_model: 'time-units',
                key: 'id_unidadFrecuencia',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'nu_frecuencia',
            numeric: true,
            disablePadding: true,
            label: 'Frecuencia',
        }
    ]
}


export const alertas = {
    title: 'Alertas',
    service: 'alerts',
    id: 'alerts',
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'id_tipo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo de alerta',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-alerts',
                vc_nameReducer: 'types-alerts',
                id_model: 'types-alerts',
                key: 'id_tipo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_usuario',
            numeric: false,
            disablePadding: true,
            label: 'Usuario',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'users-institutions',
                vc_nameReducer: 'users-institutions',
                key: 'id_usuario',
                keyTable: 'id_usuario',
                defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
                objectPath: 'usuario',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno'
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        },
        {
            id: 'tm_inicio',
            numeric: false,
            component: 'time',
            disablePadding: true,
            label: 'Hora inicio'
        },
        {
            id: 'dt_ultima',
            numeric: false,
            component: 'date',
            disablePadding: true,
            readOnly: true,
            label: 'Ultimo evento'
        },
        {
            id: 'id_unidadFrecuencia',
            numeric: false,
            disablePadding: true,
            label: 'Unidad de frecuencia',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'time-units',
                vc_nameReducer: 'time-units',
                id_model: 'time-units',
                key: 'id_unidadFrecuencia',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'nu_frecuencia',
            numeric: true,
            disablePadding: true,
            label: 'Frecuencia',
        }
    ]
}


export const calendarios = {
    title: 'Calendarios',
    service: 'calendars',
    id: 'calendars',
    allowActions: {
        create: ['*'],
        put: [],
        delete: []
    },  
    defaultSystem: [{ vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'vc_nombre',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        }
    ]
}

export const citas = {
    title: 'Eventos',
    service: 'schedules',
    id: 'schedules',
    defaultSystem: [{ vc_field: 'id_usuario', type: 'session', param: 'user', key: 'id' }],
    columns: [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id',
            hidden: true
        },
        {
            id: 'id_calendario',
            numeric: false,
            disablePadding: true,
            label: 'Calendario',
            icon: 'calendario.svg',
            required: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'users-calendars',
                vc_nameReducer: 'users-calendars',
                id_model: 'users-calendars-filter',
                key: 'id_calendario',
                keyTable: 'id_calendario',
                objectPath: 'calendario',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'id_tipo',
            numeric: false,
            disablePadding: true,
            label: 'Tipo de evento',
            required: true,
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'types-schedules',
                vc_nameReducer: 'types-schedules',
                id_model: 'types-schedules',
                key: 'id_tipo',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'vc_descripcion',
            numeric: false,
            disablePadding: true,
            label: 'Descripción',
            search: true
        },
        {
            id: 'vc_observaciones',
            numeric: false,
            disablePadding: true,
            label: 'Observaciones',
            search: true
        },
        {
            id: 'componentDateTimeRange',
            component: 'dateTimeRange',
            disablePadding: true,
            startId: 'dt_inicio',
            endId: 'dt_fin',
            fields: [
                { id: 'dt_inicio', required: true },
                { id: 'dt_fin', required: true },
            ],
            search: true,
            label: 'Desde - Hasta',
            icon: 'calendario.svg',

        },
        {
            id: 'vc_meet',
            numeric: false,
            disablePadding: true,
            icon: 'meet.svg',
            component: 'meet-button',
            label: 'Meet',
        },
        {
            id: 'id_status',
            numeric: false,
            disablePadding: true,
            label: 'Estatus',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'status-schedules',
                vc_nameReducer: 'status-schedules',
                id_model: 'status-schedules',
                key: 'id_status',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
        {
            id: 'invitados',
            numeric: false,
            disablePadding: true,
            label: 'Invitados',
            component: 'select',
            mode: 'fixed',
            relation: {
                id_type: 'toMany',
                vc_nameTable: 'schedules-users',
                vc_nameReducer: 'schedules-users',
                id_model: 'schedules-users',
                key: 'invitados',
                keyTable: 'id_usuario',
                keyItem: 'id',
                foreignKey: 'id_cita',
                objectPath: 'usuario',
                fieldsDisplay: [
                    'vc_nombre',
                    'vc_paterno',
                    'vc_materno',
                ],
                fieldsDisplaySeparators: [
                    ' ',
                    ' '
                ]
            }
        }
    ]
}

export const favorites = {
    title: 'Favoritos',
    service: 'favorites',
    id: 'favorites',
    defaultSystem: [{ vc_field: 'id_usuario', type: 'session', param: 'user', key: 'id' }, { vc_field: 'id_institucion', type: 'session', param: 'institution', key: 'id' }],
    columns: [
        {
            id: 'id_sectionDevice',
            numeric: false,
            disablePadding: true,
            label: 'Dispositivo',
            component: 'select',
            relation: {
                id_type: 'toOne',
                vc_nameTable: 'devices-sections',
                vc_nameReducer: 'devices-sections',
                id_model: 'devices-sections',
                key: 'id_sectionDevice',
                keyTable: 'id',
                fieldsDisplay: [
                    'vc_nombre'
                ],
                fieldsDisplaySeparators: [
                ]
            }
        },
    ]
}



export const institucionesMaster = { ...instituciones, id: 'institutions-masters', defaultSystem: undefined }


export default {
    usuarios,
    usuariosInstituciones,
    usuariosPermisosSecciones,
    usuariosRoles,
    instituciones,
    dependencias,
    secciones,
    dispositivos,
    dispositivosMedidas,
    dispositivosSecciones,
    eventos,
    eventosRegistos,
    entidades,
    // sexos,
    medidas,
    tiposAreas,
    tiposDispositivos,
    tiposActivosIndustriales,
    activosIndustriales,
    tiposDispositivosMedidas,
    clases,
    tiposClases,
    gruposTrabajo,
    usuariosGrupos,
    usuariosInstitucionesPermisos,
    usuariosRolesMaster,
    institucionesMaster,
    // tiposRelacionesPersonas,
    // personasRelaciones,
    // personas,
    // tiposUnidadesMedidas,
    // unidadesMedida,
    roles,
    programacionConfig,
    alertas,
    tiposAlertas,
    unidadesTiempo,
    diasSemana,
    accionesProgramadas,
    gruposActivos,
    estatusCitas,
    tiposCitas,
    citas,
    calendarios,
    usuariosCalendarios,
    usuariosCalendariosFilter,
    favorites,
    dispositivosAlarmas,
    citasUsuarios,
    meetUsuarios,
    meets
}