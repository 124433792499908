/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Slide, Grid, FormControl, Input, Button, Dialog, DialogTitle, Typography, IconButton, Box, useMediaQuery, DialogContent, DialogActions, Paper, Zoom } from '@mui/material';
import RegistroPacientes from "../RegistroPacientes";
import Close from '@mui/icons-material/CloseOutlined'
import firebase from '../../services/firebase';
import './login.css';
import ReactPlayer from 'react-player';
import { useHistory } from "react-router-dom";
import env from '@beam-australia/react-env'
import { useDispatch } from "react-redux";
import Loading from "../Loading";
import { setToken, setGlobalUser } from '../../redux/session';
import app from '../../services/index';
import { updateCatalogs } from '../../redux/catalogs';
import { catalogs } from '../../repositories';
import defaultCatalogs from '../../constants/defaultCatalogs.json';
import { useTheme } from "@mui/styles";
import Lottie from "react-lottie-player";
import lottieJson from './loading.json';
import EditableModel from "../EditableModel.js";
import CatalogsIconButton from "../customForms/CatalogsIconButton";
import { usuarios } from "../../constants/models";
const initialState = {
    registroDisplay: false,
    loading: false,
    result: null,
    optionsModal: false,
    loginOk: false,
    notification: null
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const HS = '1103760000003C0000003C0000000000000000000000000000000000000000000000000000177003E803E803E803E800000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000007294';

function Login(props) {
    const [intro, setIntro] = useState(false);
    const [open, setopen] = useState(props.open)
    const dispatch = useDispatch();
    const [play, setPlay] = useState(false)
    const [state, setState] = useState(initialState)
    const theme = useTheme();
    const history = useHistory()
    console.log('history', history.location.pathname);
    useEffect(() => {
        setopen(props.open);
    }, [props.open])
    useEffect(() => {

    }, [])
    function renderInputs() {
        const fields = getInputs()
        const regExp = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
        const valEmail = regExp.test(fields.email)
        return (
            <Grid padding={2}>
                <FormControl fullWidth>
                    <Input
                        key={fields.email.fieldid}
                        id={fields.email.fieldid}
                        field={fields.email.key}
                        name={fields.email.fieldid}
                        readOnly={fields.email.readOnly || false}
                        label={fields.email.vc_name}
                        type={fields.email.type}
                        disableUnderline
                        fullWidth
                        defaultValue={''}
                        placeholder={fields.email.name}
                        inputProps={{
                            placeholder: fields.email.name,
                            style: {
                                padding: '9px 18px',
                                borderRadius: 5,
                                height: 25,
                                border: '1px solid gray'
                            }
                        }}
                        errormessage={!valEmail ? 'E-mail no valido' : ''}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <Input
                        key={fields.password.fieldid}
                        id={fields.password.fieldid}
                        field={fields.password.key}
                        name={fields.password.fieldid}
                        readOnly={fields.password.readOnly || false}
                        label={fields.password.vc_name}
                        type={fields.password.type}
                        disableUnderline
                        fullWidth
                        defaultValue={''}
                        margin='dense'
                        placeholder={fields.password.name}
                        inputProps={{
                            placeholder: fields.password.name,
                            style: {
                                padding: '9px 18px',
                                borderRadius: 5,
                                height: 25,
                                border: '1px solid gray'
                            }
                        }}
                        style={{ marginTop: 16, minWidth: 300 }}
                    />
                    <Typography color='primary' component={'a'} marginTop={1} onClick={recuperarCuenta}>
                        {'¿Olvidaste tu contraseña?'}
                    </Typography>
                    <div className="acciones" style={{ margin: '16px 0px' }}>
                        <Button
                            fullWidth
                            variant='outlined'
                            onClick={onButtonClick}
                        >
                            {'Iniciar sesión'}
                        </Button>

                    </div>
                </FormControl>
                <Grid item xs style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CatalogsIconButton variant='text' title={'Crear usuario'} mode="title" model={usuarios} />
                    <Button color='primary' component={'a'} marginRight={1} onClick={() => { setState({ ...state, registroDisplay: true }); }}>
                        {'Comunicate con Inamex'}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    function renderUsuarioRegistro() {
        return (
            <RegistroPacientes
                registroDisplay={state.registroDisplay}
                backButton={() => {
                    setState({ ...state, registroDisplay: false })
                }}
            />
        )
    }

    function recuperarCuenta() {
        let formData = {};
        Object.values(getInputs()).map((field, index) => {
            const renderField = document.getElementById(field.key)
            if (renderField.value) {
                formData[field.key] = renderField.value
            }
        })
        const regExp = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
        const valEmail = regExp.test((formData.emailLogin || '').toLowerCase())
        // auth().sendPasswordResetEmail(formData.email,{url:`https://app.thin-k.mx/#/restablecer?email=${formData.email}`}).catch(function (error) {

        // 	console.log('respuesta a firebase', e)
        // })

        if (formData.emailLogin && valEmail) {
            const body = {
                email: formData.emailLogin
            };
            setState({ ...state, result: null, loading: true });
            window.$.ajax({
                url: `${env('REACT_APP_API_URL')}/app/resetPasswordMail/${body.email}`,
                data: body,
                method: 'GET'
            }).then((data, status, xhr, error) => {
                if (data.message) {
                    setState({ ...state, result: null, optionsModal: false, loading: false, notification: { title: data.message, message: '' } });
                }
            }).fail((data, status, xhr, error) => {
                setState({ ...state, result: null, optionsModal: false, loading: false, notification: { title: '!Ups¡', message: error || 'comprueba tu conexión a internet' } });
            });
        } else {
            setState({ ...state, result: null, optionsModal: false, loading: false, notification: { title: 'Ingresa un E-Mail valido.' } });
        }
    }
    function renderUsuarioRecuperacion() {
        return (
            <Dialog open={!!(state.email && state.vc_token && state.recoveryModal)} size='mini' basic>
                {/* <RecuperacionUsuarioModal
					email={state.email}
					vc_token={state.vc_token}
					backButton={() => {
						setState({...state, registroDisplay: false, recoveryModal: false });
					}}
				/> */}
            </Dialog>
        )
    }

    // ----------------------
    // ------  methods ------
    // ----------------------
    const loginService = async (formData) => {
        console.log('form', formData);
        if (app.authentication?.authenticated) {
            await app.logout();
        };
        app.authenticate({
            strategy: 'local',
            email: formData.email,
            password: formData.password
        })
            .then(async response => {
                console.log('Authenticated!', response);
                setState({ ...state, result: null, loading: false });
                const data = await app.get('authentication');
                // console.log('data', data);
                dispatch(setGlobalUser(data.usuario));
                dispatch(setToken(data.accessToken));
                Object.values(defaultCatalogs).map((item, index) => {
                    // console.log('item', item);
                    catalogs.initCatalogs({ name: item.name, service: item.name, data: item.data, lastUpdate: item.lastUpdate }, (catalog) => dispatch(updateCatalogs(catalog)));
                })
            })
            .catch(function (error) {
                console.error('Error authenticating!', error);
                let errorMessage = {};
                switch (error.className) {
                    case 'timeout': {
                        errorMessage.title = '¡Ups!'
                        errorMessage.message = 'Verifica tu conexión a internet'
                        break;
                    }
                    case 'not-authenticated': {
                        errorMessage.title = '¡Usuario y/o contraseña invalidos!'
                        errorMessage.message = 'Verifica tu información'
                        break;
                    }
                    default: {
                        errorMessage.title = '¡Ups algo salio mal!'
                        errorMessage.message = 'Intente de nuevo por favor'
                    }
                }
                setState({ ...state, result: null, loading: false, error: errorMessage });

            });

    }
    function onButtonClick() {
        if (state.optionsModal) {
            setState({ ...state, result: null, optionsModal: false, loading: false });
        } else {
            let formData = {};
            Object.values(getInputs()).map((field, index) => {
                console.log('key', field.key)
                const renderField = document.getElementById(field.key)
                if (renderField.value) {
                    formData[field.key.replace('Login', '')] = renderField.value
                }
            })
            console.log('form', formData)
            if (!formData.email || !formData.password) {
                setState({ ...state, result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: formData.Message || 'Verifica los campos.' } });
            } else if (!state.optionsModal) {
                const body = {
                    ...formData,
                };
                setState({ ...state, result: null, loading: true });
                try {
                    loginService(formData);
                } catch (error) {
                    if (error) {
                        console.log('error', error)
                        setState({ ...state, result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: error || 'Verifica tu conexión a internet.' } });
                    } else {
                        setState({ ...state, result: null, optionsModal: false, loading: false, notification: { title: '¡Ups!', message: 'Verifica tu conexión a internet.' } });
                    }
                }
            }
        }
    }

    function getInputs() {
        const fields = {
            email: {
                key: 'emailLogin',
                fieldid: 'emailLogin',
                required: true,
                name: 'E-mail',
                fullWidth: true,
                label: 'E-mail',
                type: 'email'
            },
            password: {
                key: 'passwordLogin',
                fieldid: 'passwordLogin',
                required: true,
                name: 'Contraseña',
                fullWidth: true,
                label: 'Contraseña',
                type: 'password'
            }
        };
        return fields;
    }

    function fowardSelector(result, stateEdit) {
        const state = stateEdit;
        if (result.instituciones.length > 0 && state.institution == null) {
            if (result.instituciones.length === 1) {
                state.institution = result.instituciones[0];
            }
        }
        if (state.institution != null) {
            if (state.institution.roles.length > 0 && state.rol == null) {
                if (state.institution.roles.length === 1) {
                    state.rol = state.institution.roles[0];
                }
            }
            if (state.institution.agendas.length > 0 && state.agenda == null) {
                if (state.institution.agendas.length === 1) {
                    state.agenda = state.institution.agendas[0];
                }
            }
        }

        if (state.institution && state.rol && state.agenda) {
            const resultFinal = {
                ...result,
                ...state
            };
            setState({ ...state, optionsModal: false }, () => { props.login(resultFinal); });
        } else if (result.instituciones.length === 0) {
            const resultFinal = {
                ...result,
                ...state
            };
            setState({ ...state, optionsModal: false }, () => { props.loginWithOutInstitution(resultFinal); });
        } else {
            setState({ ...state, result, optionsModal: true, loading: false });
        }
    }

    function selected(optionsSelected) {
        const result = {
            ...state.result,
            ...optionsSelected
        };
        setState({ ...state, optionsModal: false });
        dispatch({ type: 'SET_USER', payload: { ...result, selected: result.paciente || null } });

    }
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    return (
        <Dialog
            open={open && history?.location?.pathname !== '/video-call'}
            fullScreen={!isMd || intro}
            TransitionComponent={Transition}
            transitionDuration={500}
            BackdropProps={{ style: { backgroundColor: 'black' } }}
            onClose={() => {
                console.log('entro a home')
                history.push('/')
            }}
            onAnimationEnd={() => {
                if (!open) {
                    console.log('entro a home')
                    history.push('/')
                }
            }}
            PaperComponent={Paper}
            PaperProps={{ onKeyDown: (e) => e.keyCode === 13 && onButtonClick() }}
        >
            <DialogTitle color='primary'>
                <Box justifyContent={'center'} display={'flex'} width={'100%'}>
                    <img src="assets/images/logo192.png" style={{ backgroundColor: 'white', height: 100, padding: 2, borderRadius: '50%' }} alt='' />
                </Box>
                {'Iniciar sesión'}
            </DialogTitle>
            {renderInputs()}
            <Dialog
                open={!!state.error}
            >
                <DialogTitle>
                    {state.error?.title || ''}
                </DialogTitle>
                <DialogContent>
                    {state.error?.message || ''}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setState({ ...state, error: null })}
                    >
                        {'Ok'}
                    </Button>
                </DialogActions>
            </Dialog>
            {
                intro &&
                <Box style={{ position: 'absolute', width: '100%', top: 0, left: 0, height: '100vh', backgroundColor: 'whiteSmoke' }}>
                    {/* <Loading /> */}
                    {/* <Zoom in={intro} style={{width: '100%', height: '100%'}}> */}
                    <Lottie
                        loop={false}
                        onComplete={() => setIntro(false)}
                        animationData={lottieJson}
                        play
                        style={{ height: '100%' }}
                    />
                    {/* </Zoom> */}
                </Box>
            }
        </Dialog >
    )
}
Login.propTypes = {

}

export default Login