import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import IconMask from '../IconMask';
import SearchSelect from '../forms/SearchSelect';
import { Box, Divider, Grid, IconButton, Paper, paperClasses, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import Forms from '../forms/Forms';
import { dispositivosSecciones } from '../../constants/models';
import EditableModel from '../EditableModel.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CatalogsIconButton({ backgroundColor, component = <div />, onSave, mode = 'icon', color, sizeButton, title, size = 25, icon, item, model, variant = 'outlined', onSaveNotify = () => { } }) {
    const [open, setOpen] = React.useState(false);
    const institution = useSelector((state) => state.session.institution);
    const [editItem, setEditItem] = React.useState(item);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const handleClickOpen = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        !!onSaveNotify && onSaveNotify();
    };
    const renderButton = () => {
        switch (mode) {
            case ('title'): {
                return (
                    <Button key={'button'} size='large' variant={variant}
                        onClick={handleClickOpen}
                    >
                        {title}
                    </Button>
                )
            }
            case ('component'): {
                return (
                    <div
                        onClick={handleClickOpen}
                    >
                        {component}
                    </div>
                )
            }
            default: {
                return (
                    <IconButton
                        key={'iconButton'} size={sizeButton || 'large'} variant="outlined"
                        onClick={handleClickOpen}
                        style={{ backgroundColor: backgroundColor || theme.palette.primary.main }}
                    >
                        <IconMask color={color || 'white'} url={icon || 'assets/icons/iteration.svg'} size={size} />
                    </IconButton>
                )
            }
        }
    }
    // console.log('item', item, editItem);
    return (
        [
            renderButton(),
            <Dialog
                key={'dialog'}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                onClose={handleClose}
                fullScreen={!isMd}
            >
                {
                    open && <EditableModel model={model} item={editItem || item} onSave={onSave} onClose={handleClose} />
                }

            </Dialog >
        ]
    );
}