import app from "../services";

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
const getData = async ({ model, silent, mode = 'auto', searchText = '', rows = [], rowsPerPage = 100, page = 0 }, success = () => { }, error = () => { }) => {
    if (mode === 'fixed') {
        const filtered = rows.filter(e => JSON.stringify(e).toLowerCase().search(searchText.toLowerCase()) > -1)
        success(filtered);
    } else {
        await app.reAuthenticate()
        try {
            console.log('model', model);
            const searchFieldsFiltered = model?.searchFields?.filter(e => (e.default || e.active) && e.type === 'text');
            const response = await app.service(model.service).find({
                query: {
                    ...model?.include?.length ? {
                        include: model?.include
                    } : {},
                    $limit: rowsPerPage || 30,
                    $skip: rowsPerPage * page || 0,
                    ...model?.and?.length ? {
                        $and: model?.and || []
                    } : {},
                    ...searchFieldsFiltered.length ? {
                        $or: searchFieldsFiltered.map((searchField) => {
                            return {
                                [searchField.id]: {
                                    $like: '%' + searchText + '%'
                                }
                            }
                        })
                    } : {}
                }
            });
            console.log('response', response)

            success(response);
        } catch (e) {
            console.log('error', e)
            error(e);
        }
    }

}
const common = {
    getData,
    dataURLtoFile,
    descendingComparator,
    getComparator,
    stableSort
}
export default common;