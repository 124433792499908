import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, inputLabelClasses, ListItem, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import models from '../../constants/models';
import app from '../../services';
import { makeStyles, useTheme } from '@mui/styles';
import IconMask from '../IconMask';
import { useSelector } from 'react-redux';
import CatalogsIconButton from '../customForms/CatalogsIconButton';
import { isObject } from 'superagent/lib/utils';
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const delaySearch = debounce((callback) => {
  callback();
}, 300)
const useStyles = makeStyles(theme => ({
  label: {
    '&:hover': {
      '&:before': {
        borderBottom: ['rgba(0, 188, 212, 0.7)', '!important'],
      }
    },
  },
  underline: {
    '&:hover': {
      '&:before': {
        borderBottom: ['rgba(0, 188, 212, 0.7)', '!important'],
      }
    },
    '&:before': {
      borderBottom: 'rgba(0, 188, 212, 0.7)',
    }
  }
}))
export default function SearchSelectMultiple({ control, id, field, item = {}, defaultValue, childrens, variant = 'standard', ...props }) {
  const model = Object.values(models).find(e => e.id === field?.relation?.id_model) || {};
  const user = useSelector(state => state.session.globalUser);
  const institution = useSelector(state => state.session.institution);
  const service = app.service(field?.relation?.vc_nameTable)
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState(null);
  const [itemRemove, setItemRemove] = React.useState(null);
  const [errorItems, setErrorItems] = React.useState(null);
  const [childrensInner, setChildrensInner] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const isAdmin = institution?.roles?.find(e => e.id_rol === 'cab3b86a-09db-4808-85ff-bc235fd7b6f9' || e.id_rol === '663536e2-bdb4-4887-a313-c42d3e00bc37')
  console.log('roles', institution, user)
  const getDataBase = async (searchText) => {
    if (!item[field?.relation?.keyItem]) return;
    // await app.reAuthenticate()
    setOptions([]);
    const filtersDefault = [];

    model?.defaultSystem?.map((item, index) => {
      switch (true) {
        case (item.param === 'institution'): {
          filtersDefault.push({
            [item.vc_field]: institution[item.key]
          })
          break;
        }
        case (item.param === 'user'): {
          filtersDefault.push({
            [item.vc_field]: user[item.key]
          })
          break;
        }
        case (item.type === 'static'): {
          filtersDefault.push({
            [item.vc_field]: item.value
          })
          break;
        }
        default: { }
      }
    });
    let validation = true;
    field?.relation?.defaultSystem?.map((item, index) => {
      switch (true) {
        case (item.param === 'institution'): {
          filtersDefault.push({
            [item.vc_field]: institution[item.key]
          })
          break;
        }
        case (item.param === 'user'): {
          filtersDefault.push({
            [item.vc_field]: user[item.key]
          })
          break;
        }
        case (item.type === 'static'): {
          filtersDefault.push({
            [item.vc_field]: item.value
          })
          break;
        }
        case (item.type === 'field'): {
          const el = document.getElementById(item.key);
          // console.log('edddddd', el, el?.value);
          if (el?.value !== undefined && el?.value !== '' && el?.value !== null) {
            filtersDefault.push({
              [item.vc_field]: el?.value
            })
          } else if (item.required) {
            // console.log('valita pred', validation)
            validation = false;
          }

          break;
        }
        default: { }
      }
    })
    const and = [...model?.and || [], ...filtersDefault]
    // console.log('and', and);
    if (validation) {
      const response = await service.find({
        id_institucion: institution?.id,
        query: {
          $limit: searchText?.length > 3 ? 100 : 200,
          $skip: 0,
          $and: [...(and || []), { [field?.relation?.foreignKey]: item[field?.relation?.keyItem] }]
        }
      });
      // console.log('response?.data', response);
      setChildrensInner(response?.data || []);
    }
  }
  // const getDataBase = async () => {
  //   console.log('entro aqui', field?.relation?.keyItem, item, { [field?.relation?.foreignKey]: item[field?.relation?.keyItem] });
  //   const respon = await service.find({ query: { $and: [{ [field?.relation?.foreignKey]: item[field?.relation?.keyItem] }] } });
  //   console.log('response get', respon);
  //   if (respon?.data)
  //     setChildrensInner(respon?.data);
  // };
  React.useEffect(() => {
    if (childrens === undefined)
      getDataBase()
  }, [childrens]);

  const handleDelete = async () => {
    let query = {};
    Object.keys(itemRemove).map((key) => {
      if (!isObject(itemRemove[key])) {
        query[key] = itemRemove[key]
      }
    })
    try {
      const res = await service.remove(itemRemove.id || null, itemRemove.id ? undefined : { query: {where: query} });
      console.log(res);
      getDataBase()
      setItemRemove(null);
    } catch (error) {
      console.log(error);
      setErrorItems({ title: '¡Ups!', fields: error?.errors?.map(e => { return { label: e.message } }) })
    }
  }
  const getLabel = (item => {
    let label = '';
    if (!item) return '';
    field?.relation?.fieldsDisplay.map((key, index) => {
      label = `${label}${(field?.relation?.objectPath ? (item[field?.relation?.objectPath] || {})[key] || '' : item[key]) || ''}${field?.relation?.fieldsDisplaySeparators[index] || ''}`
    });
    return label;
  })


  return (
    [
      <Box key={'icon'} margin={1} flexDirection={'row'} display={'flex'} justifyContent={'stretch'}>
        <Typography >{field.label}</Typography>
        {isAdmin && !!item[field?.relation?.keyItem] &&
          <CatalogsIconButton
            onSaveNotify={getDataBase}
            model={{
              ...model, defaultValues: {
                ...model.defaultValues,
                [field?.relation?.foreignKey]: item[field?.relation?.keyItem]
              },
              columns: (model?.columns || []).filter(e => e.id !== field?.relation?.foreignKey)
            }}

            backgroundColor={'transparent'} sizeButton={'small'} size={20} color={theme.palette.mode === 'light' ? 'black' : 'white'} icon={'assets/icons/add.svg'} />
        }
      </Box>,
      <Box key={'childs'} style={{ backgroundColor: 'rgba(200,200,200,0.1)', padding: 4, boxShadow: '0px 0px 2px gray', borderRadius: 10, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        {childrensInner.map((child, index) => {
          console.log('childs', child);
          return (
            <CatalogsIconButton
              key={index}
              onSaveNotify={getDataBase}
              model={{
                ...model, defaultValues: {
                  ...model.defaultValues,
                  [field?.relation?.foreignKey]: item[field?.relation?.keyItem]
                },
                columns: (model?.columns || []).filter(e => e.id !== field?.relation?.foreignKey)
              }}
              item={child}
              mode='component'
              component={<Chip
                style={{ margin: 4 }}
                key={index}
                label={getLabel(child)}
                onDelete={isAdmin ? () => setItemRemove(child) : undefined}

              />}
              backgroundColor={'transparent'} sizeButton={'small'} size={20} color={theme.palette.mode === 'light' ? 'black' : 'white'} icon={'assets/icons/add.svg'} />

          )
        })}
        {
          childrensInner.length === 0 &&
          <Button fullWidth>
            {`Sin registros`}
          </Button>
        }
      </Box>,
      <Dialog
        key={'validation'}
        open={!!itemRemove}
        onClose={() => setItemRemove(null)}
      >
        <DialogTitle>{'Eliminar'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`¿Seguro que desea eliminar ${getLabel(itemRemove)}?`}
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setItemRemove(null)}>
              {'Cancelar'}
            </Button>
            <Button onClick={handleDelete}>
              {'Eliminar'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>,
      <Dialog
        key={'error'}
        open={!!errorItems}
      >
        <DialogTitle>
          {errorItems?.title}
        </DialogTitle>
        <DialogContent>
          <Typography key={'error'}>
            {errorItems?.message}
          </Typography>
          {
            errorItems?.fields?.map((item, index) => {
              return (
                <Typography key={index}>
                  {item.label}
                </Typography>
              )
            })
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorItems(null)}>
            {'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog >
    ]
  )
}
